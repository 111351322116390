.nav {
    display: flex;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    border-bottom: 1px solid #e5e5e5;
    z-index: 1;
  }
  
  .nav ul {
    width: 50%;
    height: 60px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav ul:first-child {
    margin-left: 30px;
  }
  
  .nav ul li {
    font-weight: 600;
  }
  
  .nav .brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav .brand h3 {
    font-size: 18px;
    line-height: 19px;
    font-weight: 700;
  }
  
  .nav .brand img {
    width: 32px;
    margin-right: 10px;
  }
  
  @media only screen and (max-width: 600px) {
    .nav .brand h3 {
      display: none;
    }
  }