.progressBar {
    width: calc(100vw - 100px);
    height: 65px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 50px 1fr auto;
    gap: 20px;
  }
  
  .progressBar .backButton {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin-left: 10px;
    background: #47f5901c;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .progressBar .backButton:hover {
    background: #47f5953a;
  }
  
  @media only screen and (max-width: 1024px) {
    .progressBar {
      width: calc(100vw - 60px);
      bottom: 10px;
    }
  
    .progressBar .backButton {
      width: 40px;
      height: 40px;
    }
  
    .next span:first-child {
      display: none;
    }
  }
  
  .rangeArea {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }
  
  .rangeBody {
    width: 100%;
    height: 4px;
    margin: 0 auto;
    background: #e4e8ee;
    border: none;
    outline: none;
    border-radius: 20px;
  }
  
  .progress {
    height: 4px;
    background: var(--fontPrimary);
    border-radius: 20px;
    position: relative;
    transition: width 0.3s ease;
  }
  
  .progress::after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    right: 0;
    border-radius: 100%;
    background-color: var(--fontPrimary);
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
  }
  
  .tooltip {
    position: absolute;
    top: -70px;
    
    width: 130px;
    background-color: var(--fontPrimary);
    color: #fff;
    padding: 0.8rem 1rem;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 600;
    display: none;
  }
  
  .tooltip::after {
    content: "";
    width: 15px;
    height: 15px;
    background-color: var(--fontPrimary);
    border-radius: 4px;
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: rotate(45deg) translate(-50%, 100%);
  }